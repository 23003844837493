var $jq = jQuery.noConflict();

$jq(function () {
  $jq(window).on("scroll", function () {
    var sticky = $jq(".site-header"),
      scroll = $jq(window).scrollTop();

    if (scroll >= 15) sticky.addClass("sticky");
    else sticky.removeClass("sticky");
  });

  // MOBILE MENU

  setTimeout(function () {
    $jq(".mmenu-open-fade").removeClass("mmenu-open-fade");
  }, 4000);

  // hero slider

  // news slider

  // $jq("#parentHorizontalTab").easyResponsiveTabs({
  //   type: "default", //Types: default, vertical, accordion
  //   width: "auto", //auto or any width like 600px
  //   fit: true, // 100% fit in a container
  //   tabidentify: "hor_1", // The tab groups identifier
  //   activate: function (event) {
  //     // Callback function if tab is switched
  //     var $tab = $jq(this);
  //     var $info = $jq("#nested-tabInfo");
  //     var $name = $jq("span", $info);
  //     $name.text($tab.text());
  //     $info.show();
  //   },
  // });

  $jq("#calendar").fullCalendar({
    header: {
      left: "prev,next today",
      center: "title",
      right: "month,basicWeek,basicDay",
    },
    defaultDate: "2022-10-20",
    navLinks: true, // can click day/week names to navigate views
    editable: true,
    eventLimit: true, // allow "more" link when too many events
    events: [
      {
        title: "appointment 1",
        start: "2022-10-21",
      },
      {
        title: "appointment 2",
        start: "2022-10-27",
        end: "2022-11-1",
      },
      {
        id: 999,
        title: "appointment 3",
        start: "2022-10-29T16:00:00",
      },
      {
        id: 999,
        title: "appointment 4",
        start: "2022-11-6T16:00:00",
      },
      {
        title: "appointment 5",
        start: "2022-11-9",
        end: "2022-11-13",
      },
      {
        title: "appointment 6",
        start: "2022-11-15T10:30:00",
        end: "2022-11-18T12:30:00",
      },
      {
        title: "appointment 7",
        start: "2022-11-16T1:00:00",
      },
      {
        title: "appointment 8",
        start: "2022-11-20T14:30:00",
      },
      {
        title: "appointment 9",
        start: "2022-11-23T17:30:00",
      },
      {
        title: "appointment 10",
        start: "2022-11-24T20:00:00",
      },
      {
        title: "appointment 11",
        start: "2022-11-25T07:00:00",
      },
      {
        title: "Click for Google",
        url: "https://google.com/",
        start: "2016-12-28",
      },
    ],
  });

  $jq(".sidemenu").click((e) => {
    $jq(".left_desk").addClass("active_menu");
    $jq("body").addClass("overlay");
    $jq("html").css("overflow", "hidden");
  });
  $jq(".close_icon").click((e) => {
    $jq(".left_desk").removeClass("active_menu");
    $jq("body").removeClass("overlay");
    $jq("html").css("overflow", "inherit");
  });
});

// $jq(function () {
//   var ms1 = $jq(".ms1").magicSuggest({
//     //   data: ['New York','Los Angeles','Chicago','Houston','Philadelphia','Phoenix','San Antonio','San Diego','Dallas','San Jose','Jacksonville']
//   });
// });

// $(document).ready(function ($) {
//   $(".month_slider").slick({
//     dots: false,
//     arrows: true,
//     infinite: false,
//     speed: 300,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//       // You can unslick at a given breakpoint now by adding:
//       // settings: "unslick"
//       // instead of a settings object
//     ],
//   });
// });

const main = document.querySelector(".desh_main");
// document.getElementsByTagName('body')[0].classList.add('overlay');
document.querySelector(".oepnclose_btn").addEventListener("click", function () {
  main.classList.toggle("active");
  document.getElementsByTagName("body")[0].classList.toggle("overlay");
});

$(document).ready(function ($) {
  $(".month_slider").slick({
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });

  $(function () {
    $("#datepicker").datepicker();
  });
});

$(".nav-tabs").on("click", "li", function () {
  $(".nav-tabs li.active").removeClass("active");
  $(this).addClass("active");
});

// file upload
const filepath = document.querySelector(".images_path");
const delte = document.querySelector(".img_delte");
delte.addEventListener("click", function () {
  if ((filepath.value = true)) {
    filepath.innerHTML = "";
    document.querySelector(".upload_action").classList.remove("show");
  }
});

$(document)
  .querySelector(".img_uploadfile")
  .addEventListener("change", function () {
    document.querySelector(".images_path").innerHTML = this.value.replace(
      "C:\\fakepath\\",
      ""
    );
    if ((filepath.value = true)) {
      document.querySelector(".upload_action").classList.add("show");
    }
  });
