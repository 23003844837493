const fs = require("fs");
const path = require("path");

async function generateSitemap() {
  // Generate sitemap for main routes
  const mainPages = [
    "/home",
    "/email-verify",
    "/blogs",
    "/",
    "/contact-us",
    "/about-us",
    "/faq",
    "/career",
    "/contact",
    "/terms-and-conditions",
    "/services",
    "/gift-session",
    "/gift-therapy",
    "/refund-policy",
  ];

  // Generate sitemap for therapist routes
  const therapistPages = [
    "/therapist/home",
    "/therapist/profile",
    "/therapist/my-appointments",
    "/therapist/my-patients",
    "/therapist/change-password",
    "/therapist/medical-history",
    "/therapist/medical-history-add",
    "/therapist/refer-therapist",
    "/therapist/assessment-list",
  ];

  // Generate sitemap for therapy-seeker routes
  const seekerPages = [
    "/therapy-seeker/home",
    "/therapy-seeker/profile",
    "/therapy-seeker/change-password",
    "/therapy-seeker/search-therapist",
    "/therapy-seeker/refer-therapist",
    "/therapy-seeker/my-appointments",
    "/therapy-seeker/my-assessments",
    "/therapy-seeker/failed",
    "/therapy-seeker/select-therapist",
    "/therapy-seeker/book-questionsheet-failed",
    "/therapy-seeker/mood-tracker",
    "/therapy-seeker/feedback",
  ];

  // Concatenate all pages
  const allPages = mainPages.concat(therapistPages).concat(seekerPages);

  // Generate sitemap content
  const sitemapContent = `<?xml version="1.0" encoding="UTF-8"?>
  <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${allPages.map(addPage).join("\n")}
  </urlset>`;

  fs.writeFileSync(
    path.join(__dirname, "../../../sitemap.xml"),
    sitemapContent
  ); // Adjust the path to src directory

  // Generating robots.txt file while generating sitemap
  const disallowedPages = [
    "src/app/**/_*.ts", // Exclude files starting with underscore
    "src/app/**/index.ts", // Exclude index files
  ];

  const robotsTxt = `User-agent: *
Disallow: /${disallowedPages
    .map((page) => page.replace("src/app", "").replace(".ts", ""))
    .join("\nDisallow: /")}
Allow: /
Sitemap: https://careocure.com/sitemap.xml`; // Adjust the domain and path to sitemap.xml

  fs.writeFileSync(path.join(__dirname, "../../../robots.txt"), robotsTxt); // Adjust the path to src directory
}

function addPage(page) {
  let route = page;
  route = route.startsWith("/") ? route : `/${route}`;
  return `  <url>
    <loc>${`https://careocure.com${route}`}</loc>
    <lastmod>${new Date().toISOString()}</lastmod>
    <changefreq>monthly</changefreq>
    <priority>1.0</priority>
  </url>`;
}

generateSitemap();
